export default {
  "friendlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友情链接"])},
  "moeicp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已加入萌备，备案号:萌ICP备-20212104号"])},
  "personalweb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人网站"])},
  "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提示"])},
  "zwf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["占位符啦啦啦啦啦"])},
  "developing1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本站目前还正在开发中，为您展示的是正在开发的版本，本站暂定版本号:Moe Edition。（版本更新记录详见"])},
  "developing2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([")建议您去我的"])},
  "developing3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逛一下~"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一张"])},
  "backgroundimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背景图片"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一张"])},
  "showcpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示版权页"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chihuo2104の小站"])},
  "statement1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版权声明:部分背景图片版权由"])},
  "hy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海市鹰角网络科技有限公司"])},
  "mhy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海市米哈游影铁科技有限公司"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "statement2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
  "showalive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本站已经在互联网的角落苟且偷生了"])},
  "times": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), "天", _interpolate(_named("hour")), "小时", _interpolate(_named("min")), "分", _interpolate(_named("sec")), "秒"])},
  "moenum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萌ICP备-20212104号"])},
  "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.1版本发布啦~"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])}
}