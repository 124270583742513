export default {
  "friendlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend links"])},
  "moeicp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have already joined into moeicp.Number:20212104."])},
  "personalweb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal website"])},
  "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops!"])},
  "zwf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something here....."])},
  "developing1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is still developing,it is the experiment version that you see.Version number:Moe edition.(You can see version update record in "])},
  "developing2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([")And why don't see my lovely "])},
  "developing3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "backgroundimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background Images"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "showcpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright Statement"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chihuo2104' s website"])},
  "statement1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright statement: Some of the background copyright by "])},
  "hy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shanghai Hypergryph Network Technology Co,.Ltd"])},
  "mhy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shanghai Mihoyo Network Technology Co,.Ltd"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and "])},
  "statement2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
  "showalive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site has been in the web for "])},
  "times": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " days,", _interpolate(_named("hour")), " hours,", _interpolate(_named("min")), " minutes and ", _interpolate(_named("sec")), " seconds."])},
  "moenum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeicp No.20212104"])},
  "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This application new version v3.1 has released."])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
}